<template>
  <s-crud @returnObject='refresh($event)'
    no-border
    title="Tipos de Certificaciones"
    :config="this.config"
    @save="saveDed($event)"
    add
    @clearForm="clearForm()"
    edit
    restore
    @rowSelected="rowSelected($event)" 
    remove
    :filter="filter"
   
  >
    <template scope="props">
      <v-container style="padding-top:0px;" v-if="props.item != null" class="pb-0">
        <v-row>
          <v-col cols="10" class="s-col-form">
            <s-text autofocus ref="TcDescription" v-model="props.item.TcDescription" label="Descripción" />
          </v-col>
          <v-col cols="2" class="s-col-form">
            <s-text v-model="props.item.TcDuration" label="Duración" />
          </v-col>
          <v-col cols="6" class="s-col-form">
            <s-text v-model="props.item.TcPriority" label="Prioridad" />
          </v-col>
           <v-col cols="6" class="s-col-form">
             <s-select-definition v-model="props.item.TcStatus" :def="1292" label="Estado"></s-select-definition>

          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:TcStatus="{ row }">
        <v-chip style="margin:0px" x-small :color="row.TcStatus == 1 ? 'success' : 'error'">
            {{ row.TcStatus == 1 ? "Activo" : "Inactivo" }}
        </v-chip>
      </template> 
  </s-crud>
</template>

<script>
import _sDefinitionDetail from "@/services/Technicalassistance/TypeCertification/TasTypeCertificationService.js";
export default {
  props: {
      name:"Typecertification",
    defID: 0,   
    defDescription: "",
  },
  data: () => ({
    tcid:0,
      config: {
      title: "TipoCertificaciones",
      service: _sDefinitionDetail,
      model: {
        
        TcID: "ID",
        TcDescription: "string",
        TcDuration: "int",
        TcPriority: "float",
        TcStatus: "status",
        SecStatus: "status",
      },
      headers: [
          {
            //
          text: "ID",
          value: "TcID",
          width: "5%",
          align: "end",
          sorteable: true,
        },
        {
          text: "Description",
          value: "TcDescription",
          width: "55%",
          sorteable: true,
        },
        {
          text: "Duración",
          value: "TcDuration",
          width: "15%",
          sorteable: false,
        },
        {
          text: "Prioridad",
          value: "TcPriority",
          width: "15%",
          sorteable: false,
        },
        
        {
          text: "Estado",
          value: "TcStatus",
          width: "5%",
          align: "center",
          sorteable: false,
        },
      ],
    },
  }),

  computed: {
   
   filter() {
      return {  };
    },
  },
  methods: {
 rowSelected(items) {
      if (items.length > 0) this.tcid = items[0].TcID;
      //console.log(items);
      this.$emit("rowSelected", items);
  },

    refresh(item){
      this.$emit('refresh',item);
    },
    clearForm() {
      this.$refs.TcDescription.focus();
    },
    saveDed(item) {
       
        item.UsrCreateID=0;
              item.SecStatus=1;
        if (item.TcDescription.length == 0) {
        this.$fun.alert("Registre descripción", "warning");
        return;
      }
      item.save();       
    },
  },
};
</script>
