<template>
  
    <s-crud 
    title="Detalle Tipo de Certificaciones"
    :config="configDTC"
    @save="save($event)"
    edit
    add
    remove   
    :filter="filter"
    search-input  
    ref="crud"
    >
    
   <template scope="props">
        <!--  <v-container class="pb-0"> -->
          <v-row justify="center">

             <v-col cols="10" class="s-col-form">
                <s-text v-model="props.item.TcdDescription" label="Descripción" > </s-text> 
              </v-col> 
                <v-col cols="2" class="s-col-form">
            <s-text v-model="props.item.TcdDuration" label="Duración" />
          </v-col>
                                     <v-col cols="12" class="s-col-form" sm="4" md="8" lg="4">
              <s-select-definition v-model="props.item.TcStatus" :def="1293" label="Estado"></s-select-definition>
            </v-col>                            
               </v-row>    
          </template>
     <template v-slot:TcStatus="{ row }">
        <v-chip style="margin:0px" x-small :color="row.TcStatus == 'Inactivo' ? 'error' : 'success'">
            {{ row.TcStatus}}
        </v-chip>
      </template> 
     </s-crud>

</template>


<script>
import _service from "@/services/Technicalassistance/TypeCertification/TasTypeCertificationDetailService.js";



export default {
  name: "RequirementServiceDTC",
  components: {  },
  props: {
    TcID:{ type: Number, default: 0 },
       parentID: { type: Number, default: 0 },
    addNoFunction: { type: Boolean, default: false },
  },

  data() {
    return {
      dialogEdit: false,   
     };     
  },

  computed: {
   
    configDTC() {
      return {
        model: {
          //RETORNAR PROPIEDAD COMPUTADA
          TcdID: "ID",
          TcdDescription: "string",         
          TcDescription: "string", 
           TcStatus: "status",
              SecStatus: "status",
          //
          }, 
        service: _service,
        headers:  this.$fun.getSecurity().IsLevelAdmin  
          ? [
              //Vista de tabla para admin
              { text: "ID", value: "TcdID", sortable: false },
              { text: "Descripción", value: "TcdDescription", sortable: false },
              { text: "Certificación", value: "TcDescription", sortable: false }, 
                { text: "Duración", value: "TcdDuration", sortable: false }, 
              { text: "Estado", value: "TcStatus", sortable: false },           
            ]
          : [ 
                // CABECERA EN CASO NO SE LOGEE COMO ADMIN            
            { text: "ID", value: "TcdID", sortable: false },
              { text: "Descripción", value: "TcdDescription", sortable: false },
              { text: "Certificación", value: "TcDescription", sortable: false }, 
              { text: "Duración", value: "TcdDuration", sortable: false }, 
              { text: "Estado", value: "TcStatus", sortable: false },            
            ],
      };
    }, 
    
    isAdmin() {
      this.$fun.getSecurity().IsLevelAdmin;
         },  
  },
  

  methods: {

 Initialize() {
   //DedID: this.DedID -- base de datos: campo actual
       this.filter = {TcID: this.TcID};
     
    },


    edit(item) {
      this.dialogEdit = true;
    
    },

    rowSelected(rows) {
      if (rows.length > 0) {
        this.$emit("rowSelected", rows);       
      }
    },

    save(item) {
      item.TcID=this.TcID

      if (item.TcdDescription.length == 0) {
          this.$fun.alert("Registre la descripción de la variedad", "warning");
          return;
        }
        if (item.TcID == 0) {
        this.$fun.alert("Seleccione la certificación", "warning");
        return; 
        }
        item.SecStatus=1;
        //console.log(item)
       item.save();
      }
        
  },
  watch: {
    // este DedID es el que esta en variety, vista general en DedID  ="dedID"
    TcID(){   
        
      this.Initialize();    
      },
  },
   created() {
    this.filter = { TcID: 0 };
  },
};
</script>
