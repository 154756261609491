<template>
  <div>
    <v-row>
      <v-col lg="6">
<typecertification :isAdmin="true"  @rowSelected="rowSelected($event)"  :height="100">>
</typecertification>
       
      </v-col>
      <v-col lg="6">
     <typecertificationdetail :isAdmin="true"  :TcID="tcid" :height="200"> >
        </typecertificationdetail> 
      
      </v-col>
    </v-row>
  </div>
</template>

<script>

import typecertification from "@/views/TechnicalAssistance/typeCertification/Typecertification.vue";
import typecertificationdetail from "@/views/TechnicalAssistance/typeCertification/TypeCertificationDetail.vue";
export default {
  components: {
   typecertification, typecertificationdetail
  },//

   data() {
    return {
      parentID: 0,
      tcid:0,
     
    };
  },

  methods: {
    rowSelected(rows) {
     this.tcid=rows[0].TcID;
    // alert(this.tcid)
         },
   
  },
};
</script>
